import {Colors, Pallete} from './types';

export const pallete: Pallete = {
  brand_ui_90: '#FCFBF5',
  brand_ui_80: '#F7F6E6',
  brand_ui_70: '#EDEBD0',
  brand_ui_50: '#D0CCA5',
  brand_ui_40: '#99925C',
  brand_ui_30: '#7A7337',
  brand_ui_20: '#625A28',
  brand_ui_10: '#4D4217',
  brand_ui_0: '#332D14',

  brand_ui_80_opacity_1: '#F7F6E6B2',
  brand_ui_80_opacity_2: '#F7F6E633',
  brand_ui_80_opacity_3: '#F7F6E61A',
  brand_ui_30_opacity_1: '#7A7337B2',
  brand_ui_30_opacity_2: '#7A733733',
  brand_ui_30_opacity_3: '#7A73371A',
  brand_ui_20_opacity_1: '#625A281A',
  accent_1_opacity_1: '#625A281A',
  accent_1_opacity_2: '#625A2833',
  accent_1_opacity_3: '#625A281A',

  danger_80: '#FFE5D8',
  danger_70: '#FFC5B1',
  danger_60: '#FE9E88',
  danger_50: '#FF7A6B',
  danger_40: '#FF3D3B',
  danger_30: '#DB2935',
  danger_20: '#B71D35',
  danger_10: '#931231',
  danger_0: '#7A0A2E',

  danger_40_opacity_1: '#FF3D3B1A',

  danger_50_opacity_1: '#FF7A6BB2',
  danger_50_opacity_2: '#FF7A6B33',
  danger_50_opacity_3: '#FF7A6B1A',

  success_80: '#F2FBCE',
  success_70: '#E1F89E',
  success_60: '#C8EB6B',
  success_50: '#ABD847',
  success_40: '#83BF12',
  success_30: '#69A40C',
  success_20: '#528909',
  success_10: '#3F6E06',
  success_0: '#305B02',

  success_50_opacity_1: '#ABD847B2',
  success_50_opacity_2: '#ABD84733',
  success_50_opacity_3: '#ABD8471A',
  success_40_opacity_1: '#83BF121A',

  neutral_80: '#CDFAFF',
  neutral_70: '#99F1FF',
  neutral_60: '#66DFFE',
  neutral_50: '#40CAFB',
  neutral_40: '#02A7F9',
  neutral_30: '#0081D5',
  neutral_20: '#0162B3',
  neutral_10: '#00448F',
  neutral_0: '#003176',

  neutral_50_opacity_1: '#40CAFBB2',
  neutral_50_opacity_2: '#40CAFB33',
  neutral_50_opacity_3: '#40CAFB1A',
  neutral_40_opacity_1: '#02A7F91A',

  warning_80: '#FDF6CC',
  warning_70: '#FBE899',
  warning_60: '#F5D463',
  warning_50: '#ECC03D',
  warning_40: '#E0A203',
  warning_30: '#C08400',
  warning_20: '#A06A00',
  warning_10: '#815100',
  warning_0: '#6C4001',

  warning_50_opacity_1: '#ECC03DB2',
  warning_50_opacity_2: '#ECC03D33',
  warning_50_opacity_3: '#ECC03D1A',
  warning_40_opacity_1: '#E0A2031A',

  monochrome_100: '#FFFFFF',
  monochrome_98: '#FAFAFA',
  monochrome_95: '#F2F2F2',
  monochrome_90: '#E6E6E6',
  monochrome_75: '#BFBFBD',
  monochrome_60: '#999894',
  monochrome_35: '#595855',
  monochrome_25: '#403F3D',
  monochrome_15: '#262624',
  monochrome_10: '#1A1A19',
  monochrome_5: '#0D0C07',
  monochrome_0: '#000000',

  monochrome_0_opacity_8: '#00000014',

  monochrome_98_opacity_1: '#FAFAFAB2',
  monochrome_98_opacity_2: '#FAFAFA33',
  monochrome_98_opacity_3: '#FAFAFA1A',
  monochrome_5_opacity_1: '#0D0C07B2',
  monochrome_5_opacity_2: '#0D0C0733',
  monochrome_5_opacity_3: '#0D0C071A',
  monochrome_0_opacity_08: '#00000014',
  monochrome_100_opacity_05: '#FFFFFF0D',

  brand_1: '#052923',

  brand_1_opacity_1: '#052923B2',
  brand_1_opacity_2: '#05292333',
  brand_1_opacity_3: '#0529231A',

  brand_2: '#F2FF43',

  brand_2_opacity_1: '#F2FF43B2',
  brand_2_opacity_2: '#F2FF4333',
  brand_2_opacity_3: '#F2FF431A',
};

const colors: Colors = {
  ...pallete,
  background_1: pallete.monochrome_100,
  background_2: pallete.monochrome_98,
  background_accent: pallete.brand_ui_80,

  font_color_1: pallete.monochrome_5,
  font_color_2: pallete.brand_ui_10,
  font_color_subdued: pallete.monochrome_60,

  border_color_1: pallete.monochrome_15,
  border_color_2: pallete.brand_ui_30,
  border_color_subdued: pallete.monochrome_75,

  accent: pallete.brand_ui_0,
  accent_1: pallete.brand_ui_20,
  accent_1_opacity_1: pallete.brand_ui_20_opacity_1,

  child_background_1: pallete.monochrome_95,
  child_background_2: pallete.monochrome_100,
  child_background_accent: pallete.monochrome_98_opacity_2,

  child_font_color_2: pallete.brand_ui_30,

  child_accent: pallete.brand_ui_20,

  context: {
    info: {
      light: pallete.neutral_50,
      main: pallete.neutral_30,
      dark: pallete.neutral_0,
    },
    warning: {
      light: pallete.warning_50,
      main: pallete.warning_40,
      dark: pallete.warning_0,
    },
    danger: {
      light: pallete.danger_50,
      main: pallete.danger_30,
      dark: pallete.danger_0,
    },
    success: {
      light: pallete.success_50,
      main: pallete.success_30,
      dark: pallete.success_0,
    },
  },

  button: {
    primary_mono_dark: {
      background: pallete.monochrome_5,
      selected_background: pallete.monochrome_25,
      hover_background: pallete.monochrome_15,
      text: pallete.monochrome_100,
      hover_text: pallete.monochrome_100,
      alt_text: pallete.brand_2,
      border: pallete.monochrome_5,
    },
    secondary_mono_dark: {
      background: 'transparent',
      selected_background: pallete.monochrome_5_opacity_3,
      hover_background: pallete.monochrome_5_opacity_2,
      text: pallete.monochrome_5,
      hover_text: pallete.monochrome_5,
      alt_text: pallete.brand_ui_0,
      border: pallete.monochrome_5,
    },

    primary_accent_dark: {
      background: pallete.brand_ui_20,
      selected_background: pallete.brand_ui_40,
      hover_background: pallete.brand_ui_30,
      text: pallete.monochrome_100,
      hover_text: pallete.monochrome_100,
      alt_text: pallete.brand_2,
      border: pallete.brand_ui_20,
    },
    secondary_accent_dark: {
      background: 'transparent',
      selected_background: pallete.brand_2_opacity_2,
      hover_background: pallete.brand_2_opacity_3,
      text: pallete.monochrome_5,
      hover_text: pallete.monochrome_5,
      alt_text: pallete.brand_ui_0,
      border: pallete.monochrome_5,
    },

    primary_mono_light: {
      background: pallete.monochrome_95,
      selected_background: pallete.monochrome_95,
      hover_background: pallete.monochrome_90,
      text: pallete.monochrome_5,
      hover_text: pallete.monochrome_15,
      alt_text: pallete.brand_ui_0,
      border: pallete.monochrome_95,
    },
    secondary_mono_light: {
      background: 'transparent',
      selected_background: pallete.monochrome_98_opacity_2,
      hover_background: pallete.monochrome_98_opacity_3,
      text: pallete.monochrome_95,
      hover_text: pallete.monochrome_100,
      alt_text: pallete.brand_ui_90,
      border: pallete.monochrome_95,
    },

    primary_accent_light: {
      background: pallete.brand_ui_80,
      selected_background: pallete.brand_ui_80,
      hover_background: pallete.brand_ui_70,
      text: pallete.brand_ui_10,
      hover_text: pallete.brand_ui_20,
      alt_text: pallete.warning_0,
      border: pallete.brand_ui_80,
    },
    secondary_accent_light: {
      background: 'transparent',
      selected_background: pallete.brand_2_opacity_2,
      hover_background: pallete.brand_2_opacity_3,
      text: pallete.monochrome_95,
      hover_text: pallete.monochrome_100,
      alt_text: pallete.brand_ui_90,
      border: pallete.monochrome_95,
    },
  },
};

export default colors;
