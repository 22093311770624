import axios, {AxiosError, AxiosInstance} from 'axios';

const axiosInstance: AxiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    credentials: 'include',
  },
  timeout: 120000,
  baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
});

export default axiosInstance;
