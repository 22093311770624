import {
  FontFamily,
  FontSizes,
  FontWeight,
  LineHeights,
  TextDecoration,
  TextTransform,
} from '@/theme/fonts/types';
import {BasicColors} from '@/theme/colors/types';
import {cva, cx} from 'class-variance-authority';
import styles from './styles.module.css';
import colorStyles from '@/app/colors.module.css';

export type BaseTextProps = {
  size?: keyof FontSizes;
  weight?: keyof FontWeight;
  color?: keyof BasicColors;
  casing?: keyof TextTransform;
  decoration?: keyof TextDecoration;
  as?: string;
  className?: string;
  lineHeight?: keyof LineHeights;
  family?: keyof FontFamily;
} & React.PropsWithChildren;

type LineHeightOptions = 1 | 2 | 3 | 4 | null | undefined

const baseTextVariants = cva(styles.base, {
  variants: {
    size: {
      base: styles.sizeBase,
      p_xsmall: styles.sizePxsmall,
      p_small: styles.sizePsmall,
      p_main: styles.sizePmain,
      p_medium: styles.sizePmedium,
      p_large: styles.sizePlarge,
      p_xlarge: styles.sizePxlarge,
      h6: styles.sizeH6,
      h5: styles.sizeH5,
      h4: styles.sizeH4,
      h3: styles.sizeH3,
      h2: styles.sizeH2,
      h1: styles.sizeH1,
      h_display: styles.sizeHdisplay,
    },
    weight: {
      light: styles.weightLight,
      regular: styles.weightRegular,
      medium: styles.weightMedium,
      bold: styles.weightBold,
    },
    color: colorStyles,
    casing: {
      none: styles.casingNone,
      uppercase: styles.casingUppercase,
      capitalize: styles.casingCapitalize,
      lowercase: styles.casingLowercase,
    },
    decoration: {
      none: styles.decorationNone,
      underline: styles.decorationUnderline,
      line_through: styles.decorationLineThrough,
    },
    lineHeight: {
      1: styles.lineHeight1,
      2: styles.lineHeight2,
      3: styles.lineHeight3,
      4: styles.lineHeight4,
    },
    family: {
      ui: styles.familyUi,
      brand: styles.familyBrand,
    },
  },
});

const BaseText = ({
  size = 'p_main',
  weight = 'regular',
  color = 'monochrome_5',
  lineHeight,
  casing,
  decoration,
  children,
  as,
  className,
  family = 'ui',
  ...props
}: BaseTextProps) => {
  const Component = (as || 'span') as unknown as React.ElementType;
  return (
    <Component
      className={cx(
        baseTextVariants({
          size,
          weight,
          color,
          lineHeight: lineHeight && lineHeight >= 1 && lineHeight <= 4 ? lineHeight as LineHeightOptions : null,
          casing,
          decoration,
          family,
        }),
        className,
      )}
      {...props}>
      {children}
    </Component>
  );
};

export default BaseText;
