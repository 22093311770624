import * as DialogPrimitive from '@radix-ui/react-dialog';
import {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
  forwardRef,
} from 'react';
import {X} from '@phosphor-icons/react';
import Text from '../Text';
import {cx} from 'class-variance-authority';
import styles from './styles.module.css';

export type ModalProps = DialogPrimitive.DialogProps;

const Modal = DialogPrimitive.Root;

const ModalTrigger = DialogPrimitive.Trigger;

const ModalCloseTrigger = DialogPrimitive.Close;

const ModalPortal = DialogPrimitive.Portal;

const ModalOverlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({className, ...props}, forwardedRef) => (
  <DialogPrimitive.Overlay
    ref={forwardedRef}
    className={cx(styles.overlay, className)}
    {...props}
  />
));

ModalOverlay.displayName = 'ModalOverlay';

const ModalContent = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({className, ...props}, forwardedRef) => (
  <ModalPortal>
    <ModalOverlay></ModalOverlay>
    <DialogPrimitive.Content
      ref={forwardedRef}
      className={cx(styles.content, className)}
      {...props}
    />
  </ModalPortal>
));

ModalContent.displayName = 'ModalContent';

const ModalTitle = forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({children, ...props}, forwardedRef) => {
  return (
    <DialogPrimitive.Title ref={forwardedRef} {...props} asChild>
      <Text size="p_main" asTag="p">
        {children}
      </Text>
    </DialogPrimitive.Title>
  );
});

ModalTitle.displayName = 'ModalTitle';

const ModalDescription = forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({children, ...props}, forwardedRef) => {
  return (
    <DialogPrimitive.Description ref={forwardedRef} {...props} asChild>
      <Text size="p_main" asTag="p">
        {children}
      </Text>
    </DialogPrimitive.Description>
  );
});

ModalDescription.displayName = 'ModalDescription';

const ModalClose = forwardRef<
  ElementRef<typeof DialogPrimitive.Close>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>((props, forwardedRef) => {
  return (
    <DialogPrimitive.Close
      ref={forwardedRef}
      aria-label="Close"
      asChild
      {...props}>
      <button className={styles.closeBtn}>
        <X size={20} aria-label="Close" />
      </button>
    </DialogPrimitive.Close>
  );
});

ModalClose.displayName = 'ModalClose';

const ModalHeader = ({className, ...props}: HTMLAttributes<HTMLDivElement>) => {
  return <div className={cx(styles.header, className)} {...props} />;
};

const ModalBody = ({className, ...props}: HTMLAttributes<HTMLDivElement>) => {
  return <div className={cx(styles.body, className)} {...props} />;
};

const ModalFooter = ({className, ...props}: HTMLAttributes<HTMLDivElement>) => {
  return <div className={cx(styles.footer, className)} {...props} />;
};

export {
  Modal,
  ModalTrigger,
  ModalCloseTrigger,
  ModalPortal,
  ModalOverlay,
  ModalContent,
  ModalTitle,
  ModalDescription,
  ModalClose,
  ModalHeader,
  ModalBody,
  ModalFooter,
};
