import {HeadingSizes} from '@/theme/fonts/types';
import BaseText, {BaseTextProps} from '../BaseText/BaseText';

type HeadingProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: keyof HeadingSizes;
} & React.PropsWithChildren &
  BaseTextProps;

const Heading = ({
  as = 'h1',
  size = 'h1',
  weight = 'medium',
  color = 'monochrome_5',
  lineHeight = 4,
  casing,
  decoration,
  children,
  family,
  ...props
}: HeadingProps) => {
  return (
    <BaseText
      as={as}
      size={size}
      weight={weight}
      color={color}
      casing={casing}
      decoration={decoration}
      lineHeight={lineHeight}
      family={family}
      {...props}>
      {children}
    </BaseText>
  );
};

export default Heading;
