import {ParagraphSizes} from '@/theme/fonts/types';
import BaseText, {BaseTextProps} from '../BaseText/BaseText';

export type TextProps = {
  asTag?: 'span' | 'div' | 'p' | 'label' | 'small';
  size?: keyof ParagraphSizes;
} & React.PropsWithChildren &
  BaseTextProps;

const Text = ({
  asTag = 'span',
  size = 'p_main',
  weight = 'regular',
  color = 'monochrome_5',
  lineHeight = 1,
  casing,
  decoration,
  children,
  className,
  family,
  ...props
}: TextProps) => {
  return (
    <BaseText
      className={className}
      as={asTag}
      size={size}
      weight={weight}
      color={color}
      casing={casing}
      decoration={decoration}
      lineHeight={lineHeight}
      family={family}
      {...props}>
      {children}
    </BaseText>
  );
};

export default Text;
