import type {Colors} from '@/theme/colors/types';
import React, {ButtonHTMLAttributes, ReactNode, forwardRef} from 'react';
import styles from './styles.module.css';
import {Slot, Slottable} from '@radix-ui/react-slot';
import {cva, cx} from 'class-variance-authority';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * Specify the border, text and background color
   */
  variant?: keyof Colors['button'];
  /**
   * Specify the size of the component
   */
  size?: 'xsmall' | 'small' | 'medium' | 'big';
  fullWidth?: boolean;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  asChild?: boolean;
  isIconOnly?: boolean;
  outline?: boolean;
  borderRadius?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'full';
};

const buttonVariants = cva(styles.base, {
  variants: {
    size: {
      xsmall: styles.sizeXs,
      small: styles.sizeSm,
      medium: [styles.sizeMd],
      big: [styles.sizeLg],
    },
    variant: {
      primary_mono_dark: styles.primaryMonoDark,
      secondary_mono_dark: styles.secondaryMonoDark,
      primary_accent_dark: styles.primaryAccentDark,
      secondary_accent_dark: styles.secondaryAccentDark,
      primary_mono_light: styles.primaryMonoLight,
      secondary_mono_light: styles.secondaryMonoLight,
      primary_accent_light: styles.primaryAccentLight,
      secondary_accent_light: styles.secondaryAccentLight,
    },
    borderRadius: {
      none: styles.roundedNone,
      xs: styles.roundedXs,
      sm: styles.roundedSm,
      md: styles.roundedMd,
      lg: styles.roundedLg,
      full: styles.roundedFull,
    },
    fullWidth: {
      true: styles.fullWidth,
    },
    outline: {
      true: styles.outline,
    },
    isIconOnly: {
      true: styles.iconOnly,
    },
  },
});

/**
 * Buttons indicate to the user that they can interact with it. This
 * interaction usually results in opening a dialog or marking a task as done.
 * Use buttons when your intention is not to navigate to a different page, if
 * you want to navigate is better to use a `<a href="..."></a>`.
 * However, there are times when you need to use a link that looks like a
 * button, for this cases you can use a the `as` prop to render the button as a
 * link.
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      size = 'medium',
      variant = 'primary_accent_dark',
      rightIcon,
      leftIcon,
      fullWidth,
      asChild = false,
      isIconOnly,
      outline = true,
      borderRadius = 'full',
      ...props
    },
    ref,
  ) => {
    const Component = asChild ? Slot : 'button';

    return (
      <Component
        className={cx(
          buttonVariants({
            size,
            variant,
            fullWidth,
            isIconOnly,
            borderRadius,
            outline,
          }),
          className,
        )}
        ref={ref}
        {...props}>
        {leftIcon}
        <Slottable>{children}</Slottable>
        {rightIcon}
      </Component>
    );
  },
);

Button.displayName = 'Button';

export default Button;
